@import-normalize;
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

html {
  /* Disable overflow scrolling */
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;

  /* Set striped background as fallback */
  background-color: #ccc;
  background-image: linear-gradient(
    90deg,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%
  );
  background-size: 90px;
  -webkit-tap-highlight-color: rgba(201, 40, 45, 0.6);
  color-adjust: exact;

  /* Disable overflow scrolling */
  height: 100%;
  overflow: auto;
}

p {
  font-size: 14px;
}
